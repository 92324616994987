import { useEffect, useState } from 'react';
import { FaTelegramPlane } from "react-icons/fa";

const TelegramIntegration = () => {
  const groupLink = "https://t.me/YOUR_GROUP_INVITATION_LINK";

  const [position, setPosition] = useState('bottom-10');

  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll threshold as needed
      setPosition('bottom-40');
    } else {
      setPosition('bottom-10');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a
      href={groupLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`fixed right-4 bg-blue-500 text-white rounded-full p-3 shadow-lg hover:bg-cyan-500 transition-all duration-500 ${position}`}
    >
      <FaTelegramPlane size={20} />
    </a>
  );
};

export default TelegramIntegration;
