// src/ContactUs.js
import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    designation: '',
    email: '',
    phone: '',
    institute: '',
    country: '',
    message: '',
    captcha: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log(formData);
  };

  return (
    <section className="p-6 bg-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row gap-6">
        {/* Map Section */}
        <div className="flex-1">
          <div className="bg-white p-4 rounded-lg shadow-md h-full">
            <h2 className="text-2xl font-bold mb-4">Find Us</h2>
            <div className="w-full h-64 bg-gray-200 rounded-lg mb-4">
              {/* Map Embed or Image Placeholder */}
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.0370165912564!2d77.42313681518662!3d28.64597178239626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5e7dc7e0b29%3A0x7d978a20c5a1c6f3!2sSanjay%20Nagar%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201002!5e0!3m2!1sen!2sin!4v1654500723484!5m2!1sen!2sin"
                width="100%"
                // height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
            <p className="text-gray-700">Sanjay Nagar, Ghaziabad, 201002</p>
          </div>
        </div>

        {/* Form Section */}
        <div className="flex-1">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <form onSubmit={handleSubmit} className="space-y-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name*"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="p-2 border border-gray-300 rounded-lg"
                  required
                />
                <input
                  type="text"
                  name="designation"
                  placeholder="Designation*"
                  value={formData.designation}
                  onChange={handleChange}
                  className="p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg w-full"
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone*"
                value={formData.phone}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg w-full"
                required
              />
              {/* <input
                type="text"
                name="institute"
                placeholder="Institute/Organisation*"
                value={formData.institute}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg w-full"
                required
              /> */}
              
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg w-full h-32"
              />
              {/* <input
                type="text"
                name="captcha"
                placeholder="Enter the Captcha*"
                value={formData.captcha}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg w-full"
                required
              /> */}
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
