import React, { useState } from "react";
import {
  FaHome,
  FaBox,
  FaImage,
  FaEnvelope,
  FaBars,
  FaTimes,
  FaArrowLeft,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AddProduct from "./AddProducts";
import AddImages from "./AddImages";
import ContactQueriesPage from "./ContactQueriesPage";
import Dashboard from "./Dashboard";

const Admin = () => {
  const [selectedPage, setSelectedPage] = useState("products");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handlePageChange = (page) => {
    setSelectedPage(page);
    setIsSidebarOpen(false);
  };

  const handleBackToWebsite = () => {
    if (
      window.confirm("Are you sure you want to navigate back to the website?")
    ) {
      navigate("/"); // Redirect to the root route
    }
  };

  return (
    <div className='flex h-screen'>
      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-gray-100 border-r border-gray-300 z-40 transform md:translate-x-0 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:relative md:block`}
      >
        <div className='p-4 mt-10'>
          <h2 className='text-xl font-bold mb-4'>Admin Panel</h2>
          <ul className='space-y-2'>
            <li>
              <button
                onClick={() => handlePageChange("dashboard")}
                className='flex items-center space-x-2 w-full p-2 hover:bg-gray-200 rounded'
              >
                <FaHome />
                <span>Dashboard</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => handlePageChange("products")}
                className='flex items-center space-x-2 w-full p-2 hover:bg-gray-200 rounded'
              >
                <FaBox />
                <span>Products</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => handlePageChange("images")}
                className='flex items-center space-x-2 w-full p-2 hover:bg-gray-200 rounded'
              >
                <FaImage />
                <span>Images</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => handlePageChange("contact")}
                className='flex items-center space-x-2 w-full p-2 hover:bg-gray-200 rounded'
              >
                <FaEnvelope />
                <span>Contact Queries</span>
              </button>
            </li>
            <li>
              <button
                onClick={handleBackToWebsite}
                className='flex items-center space-x-2 w-full p-2 hover:bg-gray-200 rounded'
              >
                <FaArrowLeft />
                <span>Back to Website</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Content Area */}
      <div
        className={`flex-1  p-4 transition-opacity duration-300 ease-in-out mt-10 ${
          isSidebarOpen ? "opacity-50" : "opacity-100"
        } ${isSidebarOpen ? "md:opacity-100" : ""}`}
      >
        {selectedPage === "dashboard" && (
          <div>
            <Dashboard />
          </div>
        )}

        {selectedPage === "products" && (
          <div>
            <AddProduct />
          </div>
        )}

        {selectedPage === "images" && (
          <div>
            <AddImages />
          </div>
        )}

        {selectedPage === "contact" && (
          <div>
            <ContactQueriesPage />
          </div>
        )}
      </div>

      {/* Sidebar Toggle Button */}
      <button
        className='md:hidden fixed top-4 left-4 z-50 text-gray-600'
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </button>
    </div>
  );
};

export default Admin;
