import React, { useState } from "react";
import eduTeachPioneersImg from "../assets/Pioneer.jpg";
import coursesImg from "../assets/Courses.jpg";
import deepUnderstandingImg from "../assets/DeepUnderstanding.jpg";
import customerSuccessImg from "../assets/Customer.jpg";
import visionaryImg from "../assets/Visionary.jpg";
import {
  FaShieldAlt,
  FaCheckCircle,
  FaChartLine,
  FaCode,
  FaPencilRuler,
} from "react-icons/fa";
// Import components for specific reasons
import { Link } from "react-router-dom";
// Add more components as needed

function EduTeachReasons() {
  // State to track the selected reason
  const [selectedReason, setSelectedReason] = useState("Courses");

  // Define reasons with an additional field to determine if they have a component
  const reasons = [
    {
      image: eduTeachPioneersImg,
      title: "EduTeach Pioneers",
      description:
        "Since 1991, we continue to believe in the power of technology to transform the education process and the power of education to build innovative future generations.",
      hasComponent: false, // No component for this reason
    },
    {
      image: coursesImg,
      title: "Courses",
      description:
        "We believe in lifelong learning and hence provide learning solutions across the spectrum from K-12 Education, Vocational Education, Higher Education and Corporate Learning.",
      hasComponent: true, // Has a component to render
    },
    {
      image: deepUnderstandingImg,
      title: "Deep Understanding",
      description:
        "Our deep understanding of education and technology systems help us to effectively address learning needs at all levels.",
      hasComponent: false, // No component for this reason
    },
    {
      image: customerSuccessImg,
      title: "Customer Success",
      description:
        "We continuously think future forward and strive to provide solutions that empower youth and nations to be productive and prosperous in the Fourth Industrial era.",
      hasComponent: false, // No component for this reason
    },
    {
      image: visionaryImg,
      title: "Visionary",
      description:
        "We continuously think future forward and strive to provide solutions that empower youth and nations to be productive and prosperous in the Fourth Industrial era.",
      hasComponent: false, // No component for this reason
    },
  ];

  const coursesData = [
    {
      id: 13,
      title: "Cybersecurity",
      icon: <FaShieldAlt className='text-red-500 text-3xl mr-3' />,
      description:
        "Dive deep into the world of cybersecurity with our advanced training program. Gain expertise in AI-powered security solutions and threat detection. Learn ethical hacking, advanced security protocols, and incident response strategies to protect sensitive data and systems in an increasingly digital world.",
      features: [
        "Immersive hands-on labs to practice real-world cybersecurity techniques",
        "Access to the latest cutting-edge security tools",
        "In-depth insights on emerging security threats and trends",
        "Networking opportunities with top cybersecurity professionals",
        "Industry-recognized certification to boost your cybersecurity credentials",
      ],
    },
    {
      id: 11,
      title: "Data Analyst",
      icon: <FaChartLine className='text-green-500 text-3xl mr-3' />,
      description:
        "Become a proficient data analyst with our comprehensive course. Master data analysis techniques and tools, focusing on extracting valuable insights from large datasets using Python and SQL. Develop skills in data visualization, statistical analysis, and predictive modeling to become an indispensable asset in any organization.",
      features: [
        "Hands-on projects using real-world datasets to build practical skills",
        "Expert-led video lectures for in-depth understanding",
        "Access to exclusive data analysis tools and software",
        "Opportunities for collaboration and networking with peers",
        "Certification upon completion to validate your data analysis expertise",
      ],
    },
    {
      id: 12,
      title: "Full Stack Java Development",
      icon: <FaCode className='text-blue-500 text-3xl mr-3' />,
      description:
        "Master the art of Java programming and full-stack development with our comprehensive course. Learn to build robust web applications using front-end technologies like HTML, CSS, and JavaScript, along with back-end frameworks such as Spring Boot and Hibernate. Gain the skills needed to excel in the competitive world of software development.",
      features: [
        "Project-based learning with real-life applications for hands-on experience",
        "Interactive coding exercises and quizzes to reinforce learning",
        "Mentorship from seasoned developers to guide your learning journey",
        "Access to a vibrant online community of fellow learners and developers",
        "Job-ready skills to enhance your career prospects and opportunities",
      ],
    },
    {
      id: 14,
      title: "TCS iON National Qualifier Test",
      icon: <FaPencilRuler className='text-purple-500 text-3xl mr-3' />,
      description:
        "Prepare for the TCS iON National Qualifier Test with our comprehensive guide. This course provides an in-depth understanding of the test structure and content, covering quantitative aptitude, verbal ability, and logical reasoning. Enhance your problem-solving skills and get ready to excel in one of the most recognized employment tests in India.",
      features: [
        "Detailed coverage of all test sections: quantitative aptitude, verbal ability, and logical reasoning",
        "Practice tests and mock exams to simulate the real test environment",
        "Tips and strategies from experts to maximize your score",
        "Study materials and resources to reinforce learning",
        "Certification to boost your credentials and improve job prospects",
      ],
    },
  ];

  return (
    <section className='p-6 bg-gray-100'>
      <div className='container mx-auto'>
        <h2 className='text-3xl font-bold text-center mb-8'>Why EduTeach?</h2>
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-6'>
          {reasons.map((reason, index) => (
            <div
              key={index}
              onClick={() =>
                reason.hasComponent && setSelectedReason(reason.title)
              }
              className={`bg-white p-3 rounded-lg shadow-md cursor-pointer ${
                reason.hasComponent
                  ? "hover:shadow-xl hover:scale-105"
                  : "hover:scale-100"
              } duration-700 ${
                selectedReason === reason.title
                  ? "border-2 border-blue-500"
                  : ""
              }`}
            >
              <div className='flex items-center justify-center mb-2'>
                <img
                  src={reason.image}
                  alt={reason.title}
                  className='h-[100px] md:h-[200px] object-cover w-full rounded-xl'
                />
              </div>
              <h3 className='text-md font-semibold text-center'>
                {reason.title}
              </h3>
            </div>
          ))}
        </div>

        {/* Render the selected reason's component */}
        <div className='mt-8'>
          {selectedReason === "Courses" && (
            <>
              <h2 className='text-2xl md:text-2xl font-bold  p-2'>
                Popular <span className='underline text-blue-600'>Courses</span>
              </h2>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 p-2'>
                {coursesData.map((course) => (
                  <div
                    key={course.id}
                    className='bg-gray-200 shadow-xl rounded-lg p-4 flex flex-col md:flex-row'
                  >
                    <div className='flex items-center mb-4 md:mb-0 mr-5'>
                      <div className='w-12 h-12 flex items-center justify-center bg-gray-200 rounded-full'>
                        {course.icon}
                      </div>
                      <div>
                        <h2 className='text-lg font-semibold'>
                          {course.title}
                        </h2>
                      </div>
                    </div>
                    <div className='flex flex-col justify-between'>
                      <p className='text-sm mb-2'>{course.description}</p>

                      <Link
                        to='https://forms.gle/VKXEt2QtAuxjZK47A'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <button className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors'>
                          Register Now
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {/* Add similar conditional rendering for other components */}
        </div>
      </div>
    </section>
  );
}

export default EduTeachReasons;
