import React from "react";

// Sample static data with Indian context
const contactQueries = [
  {
    name: "Amit Sharma",
    email: "amit.sharma@example.in",
    phone: "+91-9876543210",
    address: "12/34, New Delhi, Delhi 110001",
    message: "Interested in product details.",
  },
  {
    name: "Sneha Patel",
    email: "sneha.patel@example.in",
    phone: "+91-9123456789",
    address: "56, Mumbai, Maharashtra 400001",
    message: "Requesting a callback for sales inquiry.",
  },
  {
    name: "Rajesh Kumar",
    email: "rajesh.kumar@example.in",
    phone: "+91-9988776655",
    address: "78/9, Bangalore, Karnataka 560001",
    message: "Question about service availability.",
  },
  {
    name: "Priya Singh",
    email: "priya.singh@example.in",
    phone: "+91-9445566778",
    address: "101, Chennai, Tamil Nadu 600001",
    message: "Need support for an existing issue.",
  },
  {
    name: "Ravi Verma",
    email: "ravi.verma@example.in",
    phone: "+91-9911223344",
    address: "202, Hyderabad, Telangana 500001",
    message: "Inquiring about pricing and plans.",
  },
  {
    name: "Geeta Rani",
    email: "geeta.rani@example.in",
    phone: "+91-9888776655",
    address: "303, Pune, Maharashtra 411001",
    message: "Feedback on recent purchase.",
  },
  {
    name: "Arjun Mehta",
    email: "arjun.mehta@example.in",
    phone: "+91-9777888999",
    address: "404, Kolkata, West Bengal 700001",
    message: "Request for service upgrade.",
  },
  {
    name: "Nisha Agarwal",
    email: "nisha.agarwal@example.in",
    phone: "+91-9123456780",
    address: "505, Surat, Gujarat 395001",
    message: "Query regarding refund process.",
  },
  {
    name: "Vikram Patel",
    email: "vikram.patel@example.in",
    phone: "+91-9333445566",
    address: "606, Jaipur, Rajasthan 302001",
    message: "Information about new arrivals.",
  },
  {
    name: "Maya Sinha",
    email: "maya.sinha@example.in",
    phone: "+91-9944556677",
    address: "707, Chandigarh, Punjab 160001",
    message: "Assistance with account issues.",
  },
];

const ContactQueriesPage = () => {
  return (
    <div>
      <h2 className='text-2xl font-bold mb-4'>Contact Queries</h2>
      <p className='mb-4'>View and manage contact queries here.</p>
      <div className='overflow-x-auto'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-100'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                S.No
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Name
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Email
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Phone
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Address
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                Message
              </th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {contactQueries.map((query, index) => (
              <tr key={index}>
                <td className='px-6 py-4 whitespace-nowrap'>{index + 1}</td>{" "}
                {/* Serial Number */}
                <td className='px-6 py-4 whitespace-nowrap'>{query.name}</td>
                <td className='px-6 py-4 whitespace-nowrap'>{query.email}</td>
                <td className='px-6 py-4 whitespace-nowrap'>{query.phone}</td>
                <td className='px-6 py-4 whitespace-nowrap'>{query.address}</td>
                <td className='px-6 py-4 whitespace-nowrap'>{query.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactQueriesPage;
