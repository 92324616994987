import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import CompanyInfo from "./utils/CompanyInfo";
import ContactUs from "./pages/ContactUs";
import { navLinks } from "./ProjectData";
import CategoryPage from "./utils/CategoryPage";
import Login from "./utils/Login";
import Admin from "./utils/Admin/Admin";
import TelegramIntegration from "./utils/TelegramIntegration";
import WhatsAppIntegration from "./utils/WhatsAppIntegration";
import ScrollToTop from "./utils/ScrollToTop";
import { IoIosCloseCircle } from "react-icons/io";
import PopupImage from "./assets/Popup.jpg";

const MainLayout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname === "/admin";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);
  return (
    <div className='flex flex-col'>
      {!isAdminRoute && <Navbar />}
      <main className='flex-grow'>{children}</main>
      {!isAdminRoute && <Footer />}
    </div>
  );
};

const App = () => {
  const [showPopup, setShowPopup] = useState(true); // State for popup visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false); // Hide popup after 7 seconds
    }, 7000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false); // Close popup on button click
  };

  return (
    <Router>
      <MainLayout>
        {showPopup && (
          <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50 '>
            <div className='relative rounded-lg shadow-lg max-w-sm w-full bg-white p-3 text-black'>
              {/* Close Icon */}
              <button
                onClick={handleClosePopup}
                className='absolute top-4 right-4 text-white hover:text-gray-800'
              >
                <IoIosCloseCircle size={35} className='w-8 h-8' />
              </button>
              {/* Clickable Image */}
              <Link
                to='https://forms.gle/VKXEt2QtAuxjZK47A'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={PopupImage}
                  alt='Popup Content'
                  className='w-full rounded-lg h-[350px]'
                />
              </Link>
              {/* Static Text Below the Image */}
              <div className='text-sm text-center font-semibold'>
                <p>Eduteach brings a golden opportunity for students</p>
                <p>20 days ONLINE Advance Excel + Power BI course</p>
                <p>Batch Starts from 1st week of October</p>
                <p className='font-bold text-lg'>FIRST COME FIRST SERVE</p>
                <p>
                  <button className='bg-blue-600 p-2 rounded-xl text-center text-white'>
                    <Link
                      to='https://forms.gle/VKXEt2QtAuxjZK47A'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Register ASAP
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/login' element={<Login />} />
          <Route path='/about' element={<About />} />
          <Route path='/company-info' element={<CompanyInfo />} />
          <Route path='/contact' element={<ContactUs />} />
          {navLinks.map((link) =>
            link.items.map((item) =>
              item.nested.map((nestedItem) => (
                <Route
                  key={`${link.name}-${item.name}-${nestedItem}`}
                  path={`/${link.name
                    .toLowerCase()
                    .replace(/ /g, "-")}/${nestedItem
                    .toLowerCase()
                    .replace(/ /g, "-")}`}
                  element={
                    <CategoryPage
                      category={link.name}
                      subCategory={item.name}
                      item={nestedItem}
                    />
                  }
                />
              ))
            )
          )}
        </Routes>
        <TelegramIntegration />
        <WhatsAppIntegration />
        <ScrollToTop />
      </MainLayout>
    </Router>
  );
};

export default App;
