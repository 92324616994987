import React, { useState } from "react";
import { Link } from "react-router-dom";
import IndustriesWeServe from "../utils/IndustriesWeServe";
import OurWorks from "../utils/OurWorks";
import { FaTools, FaStar, FaNewspaper, FaPhoneAlt } from "react-icons/fa";
import Slider from "react-slick"; // Import react-slick for carousel
import CompanyInfo from "../utils/CompanyInfo";
import VideoSlider from "../utils/Video";
import OurFeaturedServices from "../utils/FeaturedService";
import TestimonialSection from "../utils/Testimonial";
import Image1 from "../assets/LandingImg.jpg";
import {
  FaShieldAlt,
  FaCheckCircle,
  FaChartLine,
  FaCode,
  FaPencilRuler,
} from "react-icons/fa";
import EduTeachReasons from "./EdTechReasons";
import Contact from "../utils/ContactUs";
const testimonials = [
  {
    quote:
      "The services provided were exceptional. We highly recommend this company!",
    name: "John Doe",
    position: "CEO, Company A",
  },
  {
    quote:
      "A fantastic experience from start to finish. Highly professional team!",
    name: "Jane Smith",
    position: "Manager, Company B",
  },
  {
    quote:
      "We are extremely satisfied with the results. Will definitely use their services again.",
    name: "Emily Johnson",
    position: "Director, Company C",
  },
  {
    quote:
      "Their attention to detail and customer service is top-notch. Highly recommended!",
    name: "Michael Brown",
    position: "Founder, Company D",
  },
  {
    quote:
      "A reliable partner with excellent service quality. Great experience!",
    name: "Sarah Wilson",
    position: "Marketing Head, Company E",
  },
];

const Home = () => {
  const [expandedCourseId, setExpandedCourseId] = useState(null);

  const toggleCourse = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };
  // Settings for the slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const coursesData = [
    {
      id: 13,
      title: "Cybersecurity",
      icon: <FaShieldAlt className='text-red-500 text-3xl mr-3' />,
      description:
        "Dive deep into the world of cybersecurity with our advanced training program. Gain expertise in AI-powered security solutions and threat detection. Learn ethical hacking, advanced security protocols, and incident response strategies to protect sensitive data and systems in an increasingly digital world.",
      features: [
        "Immersive hands-on labs to practice real-world cybersecurity techniques",
        "Access to the latest cutting-edge security tools",
        "In-depth insights on emerging security threats and trends",
        "Networking opportunities with top cybersecurity professionals",
        "Industry-recognized certification to boost your cybersecurity credentials",
      ],
    },
    {
      id: 11,
      title: "Data Analyst",
      icon: <FaChartLine className='text-green-500 text-3xl mr-3' />,
      description:
        "Become a proficient data analyst with our comprehensive course. Master data analysis techniques and tools, focusing on extracting valuable insights from large datasets using Python and SQL. Develop skills in data visualization, statistical analysis, and predictive modeling to become an indispensable asset in any organization.",
      features: [
        "Hands-on projects using real-world datasets to build practical skills",
        "Expert-led video lectures for in-depth understanding",
        "Access to exclusive data analysis tools and software",
        "Opportunities for collaboration and networking with peers",
        "Certification upon completion to validate your data analysis expertise",
      ],
    },
    {
      id: 12,
      title: "Full Stack Java Development",
      icon: <FaCode className='text-blue-500 text-3xl mr-3' />,
      description:
        "Master the art of Java programming and full-stack development with our comprehensive course. Learn to build robust web applications using front-end technologies like HTML, CSS, and JavaScript, along with back-end frameworks such as Spring Boot and Hibernate. Gain the skills needed to excel in the competitive world of software development.",
      features: [
        "Project-based learning with real-life applications for hands-on experience",
        "Interactive coding exercises and quizzes to reinforce learning",
        "Mentorship from seasoned developers to guide your learning journey",
        "Access to a vibrant online community of fellow learners and developers",
        "Job-ready skills to enhance your career prospects and opportunities",
      ],
    },
    {
      id: 14,
      title: "TCS iON National Qualifier Test",
      icon: <FaPencilRuler className='text-purple-500 text-3xl mr-3' />,
      description:
        "Prepare for the TCS iON National Qualifier Test with our comprehensive guide. This course provides an in-depth understanding of the test structure and content, covering quantitative aptitude, verbal ability, and logical reasoning. Enhance your problem-solving skills and get ready to excel in one of the most recognized employment tests in India.",
      features: [
        "Detailed coverage of all test sections: quantitative aptitude, verbal ability, and logical reasoning",
        "Practice tests and mock exams to simulate the real test environment",
        "Tips and strategies from experts to maximize your score",
        "Study materials and resources to reinforce learning",
        "Certification to boost your credentials and improve job prospects",
      ],
    },
  ];

  return (
    <div className='mt-10'>
      {/* Image Slider */}
      <div
        className='relative h-screen w-full bg-cover bg-center bg-no-repeat'
        style={{
          backgroundImage: `url(${Image1})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className='absolute inset-0 flex flex-col justify-center items-center space-y-4'>
          <h2 className=' text-2xl lg:text-4xl font-bold text-white  mt-[6%]'>
            Empowering you to learn and grow
          </h2>
          <button className='bg-blue-500 text-white px-4 py-2 lg:py-2 rounded-full text-lg md:text-sm'>
            <Link
              to='https://forms.gle/VKXEt2QtAuxjZK47A'
              target='_blank'
              rel='noopener noreferrer'
            >
              Know More
            </Link>
          </button>
        </div>
      </div>

      <EduTeachReasons />
      {/* Company Info Link */}

      {/* <OurFeaturedServices /> */}

      {/* Testimonials */}
      <TestimonialSection />

      <Contact />
    </div>
  );
};

export default Home;
