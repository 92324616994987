import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const modalStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
};

const backdropStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 999,
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  background: "#333333",
  borderRadius: "5px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(8.2px)",
  border: "1px solid #fff",
  width: "17em",
  height: "12em",
};

const contentStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginTop: "auto",
  marginBottom: "auto",
};

const inputWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  gap: "10px", // Space between input boxes
};

const inputStyles = {
  color: "#fff",
  height: "50px", // Fixed height
  width: "50px", // Fixed width
  textAlign: "center",
  background: "#00000000",
  border: "1px #fff solid",
  borderRadius: "5px",
  transition: "background-color 0.3s ease, border-color 0.3s ease",
  fontSize: "larger",
};

const inputFilledStyles = {
  ...inputStyles,
  backgroundColor: "#fff",
  color: "#000",
};

const buttonStyles = {
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#00000000",
  color: "#fff",
  width: "8.5em",
  height: "2.3em",
  border: "#fff 0.1em solid",
  borderRadius: "5px",
  transition: "all 0.5s ease",
};

const buttonHoverStyles = {
  ...buttonStyles,
  backgroundColor: "#fff",
  color: "#000",
};

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isOtpCorrect, setIsOtpCorrect] = useState(true);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    const otpString = otp.join(""); // Concatenate array elements into a single string
    console.log(otpString); // Log the full OTP string
    const correctOtp = "0000";
    if (otpString === correctOtp) {
      alert("Success");
      navigate("/admin"); // Redirect to home page
    } else {
      setIsOtpCorrect(false);
    }
  };

  return (
    <div className='login-page'>
      <button
        onClick={handleLoginClick}
        style={buttonStyles}
        onMouseOver={(e) => (e.currentTarget.style = buttonHoverStyles)}
        onMouseOut={(e) => (e.currentTarget.style = buttonStyles)}
      >
        Login
      </button>

      {isModalOpen && (
        <>
          <div style={backdropStyles} onClick={handleCloseModal}></div>
          <div style={modalStyles}>
            <form style={formStyles} onSubmit={handleSubmitOtp}>
              <div style={contentStyles}>
                <p
                  align='center'
                  style={{ color: "#fff", fontWeight: "bolder" }}
                >
                  Enter your OTP Code
                </p>
                <div style={inputWrapperStyles}>
                  {[...Array(4)].map((_, index) => (
                    <input
                      key={index}
                      maxLength='1'
                      type='text'
                      placeholder=''
                      style={inputStyles}
                      value={otp[index]} // Bind input value
                      onChange={(e) => handleOtpChange(e, index)}
                      onFocus={(e) => (e.target.style = inputFilledStyles)}
                      onBlur={(e) => (e.target.style = inputStyles)}
                    />
                  ))}
                </div>
                {!isOtpCorrect && (
                  <p style={{ color: "red" }}>
                    Incorrect OTP. Please try again.
                  </p>
                )}
                <button
                  type='submit'
                  style={buttonStyles}
                  onMouseOver={(e) =>
                    (e.currentTarget.style = buttonHoverStyles)
                  }
                  onMouseOut={(e) => (e.currentTarget.style = buttonStyles)}
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
