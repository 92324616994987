import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Example testimonial data
const testimonials = [
  {
    quote:
      "Eduteach Education Services has transformed my learning experience. The interactive courses and expert instructors have made complex topics easy to understand.",
    name: "Ravi Sharma",
    position: "Engineering Student, IIT Delhi",
    image:
      "https://static.vecteezy.com/system/resources/thumbnails/005/427/608/small_2x/young-indian-student-holding-diary-file-in-hand-free-photo.JPG",
  },
  {
    quote:
      "The personalized learning path provided by Eduteach has helped me excel in my studies. The support from mentors is top-notch!",
    name: "Anita Desai",
    position: "Medical Student, AIIMS",
    image:
      "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDI0LTAzL2ZyZWVpbWFnZXNjb21wYW55X3Bob3RvX29mX3lvdW5nX2luZGlhbl9naXJsX2hvbGRpbmdfc3R1ZGVudF9iYV8zN2QyNjU4Yi0yOWIwLTQyZmQtODhmYy04OGU3ZTcxYmVlNDcucG5n.png",
  },
  {
    quote:
      "Eduteach’s platform is user-friendly and effective. The real-time feedback and practice tests have been instrumental in my exam preparations.",
    name: "Amit Patel",
    position: "Commerce Student, Mumbai University",
    image:
      "https://oidigitalinstitute.com/wp-content/uploads/2022/07/pexels-padmathilaka-wanigasekara-7616706-1024x683.jpg",
  },
  {
    quote:
      "I have gained so much knowledge through Eduteach’s courses. The flexibility to learn at my own pace has been a game-changer for me.",
    name: "Sita Rao",
    position: "Arts Student, Delhi University",
    image:
      "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDI0LTAzL2ZyZWVpbWFnZXNjb21wYW55X3Bob3RvX29mX3lvdW5nX2luZGlhbl9naXJsX2hvbGRpbmdfc3R1ZGVudF9iYV8zN2QyNjU4Yi0yOWIwLTQyZmQtODhmYy04OGU3ZTcxYmVlNDcucG5n.png",
  },
  {
    quote:
      "Eduteach Education Services offers a wealth of resources and a supportive community. It has greatly enhanced my academic performance and career prospects.",
    name: "Raj Kumar",
    position: "Computer Science Student, NIT Trichy",
    image:
      "https://images.unsplash.com/photo-1604177091072-b7b677a077f6?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwc3R1ZGVudHxlbnwwfHwwfHx8MA%3D%3D",
  },
  {
    quote:
      "The quality of education and the range of subjects available on Eduteach are impressive. It’s been an invaluable resource throughout my academic journey.",
    name: "Neha Agarwal",
    position: "MBA Student, IIM Bangalore",
    image:
      "https://w7.pngwing.com/pngs/536/316/png-transparent-iiht-kalkaji-test-of-english-as-a-foreign-language-toefl-student-alumni-association-indian-institute-of-hardware-technology-student-people-india-girl-thumbnail.png",
  },
  {
    quote:
      "Eduteach has provided me with the tools and knowledge to excel in my field. The practical insights and industry-relevant courses have been incredibly useful.",
    name: "Vikram Singh",
    position: "Engineering Student, BITS Pilani",
    image:
      "https://t3.ftcdn.net/jpg/04/76/17/70/360_F_476177047_XHsk4GWUeHF5g8YJao1CkWAVBOFpeBZV.jpg",
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const TestimonialSection = () => {
  return (
    <section className='p-2 lg:p-10 bg-gray-100 overflow-x-hidden'>
      <div className='container mx-auto px-4 md:px-8 flex flex-col-reverse md:flex-row-reverse gap-4 '>
        {/* Left Side */}
        <div className='md:w-2/3  rounded-xl '>
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className='p-2 bg-white rounded-lg shadow-lg flex items-center '
              >
                <img
                  src={testimonial.image}
                  alt={`${testimonial.name}'s photo`}
                  className='w-20 h-20 rounded-full mr-4'
                />
                <div className=''>
                  <p className='text-gray-600 mb-1'>"{testimonial.quote}"</p>
                  <h4 className='text-blue-600 font-semibold'>
                    {testimonial.name}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* Right Side */}
        <div className='md:w-1/3 mb-8 md:mb-0 '>
          <h2 className='text-2xl md:text-2xl text-center font-bold mb-4'>
            Testimonials
          </h2>
          <p className='text-gray-600'>
            Hear from our satisfied learners who have experienced our
            educational services firsthand. Their feedback highlights the
            quality and commitment we bring to every learning journey.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
