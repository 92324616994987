import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  // Example stats data
  const stats = [
    { label: "Total Products", value: 120, color: "bg-blue-500" },
    { label: "Used Images", value: 80, color: "bg-green-500" },
    { label: "Contact Queries", value: 15, color: "bg-yellow-500" },
    { label: "Pending Orders", value: 45, color: "bg-red-500" },
  ];

  // Static data for products, used images, and contact queries
  const products = [
    { id: 1, name: "Product A", category: "Category 1" },
    { id: 2, name: "Product B", category: "Category 2" },
    { id: 3, name: "Product C", category: "Category 1" },
    // Add more products as needed
  ];

  const usedImages = [
    {
      id: 1,
      src: "http://crownhardware.co.in/files/resized/426697/265;265;b0e4160d169ac5fc09adeebcc3b84ffbad987b09.jpg",
      description: "Image 1",
    },
    {
      id: 2,
      src: "http://crownhardware.co.in/files/resized/301255/265;265;b155ff63f62e71cc85941b89c2b706ca4fbfa36b.jpg",
      description: "Image 2",
    },
    {
      id: 3,
      src: "http://crownhardware.co.in/files/resized/426697/265;265;b0e4160d169ac5fc09adeebcc3b84ffbad987b09.jpg",
      description: "Image 3",
    },
    {
      id: 4,
      src: "http://crownhardware.co.in/files/resized/301255/265;265;b155ff63f62e71cc85941b89c2b706ca4fbfa36b.jpg",
      description: "Image 4",
    },
    // Add more images as needed
  ];

  const contactQueries = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      phone: "123-456-7890",
      address: "123 Main St",
      message: "Inquiry about product X",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      phone: "987-654-3210",
      address: "456 Elm St",
      message: "Request for a demo",
    },
    // Add more queries as needed
  ];

  return (
    <div className=''>
      <h2 className='text-2xl font-bold mb-6'>Dashboard</h2>

      {/* Stats Section */}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-6'>
        {stats.map((stat) => (
          <div
            key={stat.label}
            className={`p-4 rounded-lg shadow-xl text-white ${stat.color} `}
          >
            <h3 className='text-lg font-semibold'>{stat.label}</h3>
            <p className='text-2xl font-bold'>{stat.value}</p>
          </div>
        ))}
      </div>

      {/* All Products Section */}
      <div className='mb-6'>
        <h3 className='text-xl font-bold mb-4'>All Products</h3>
        <table className='min-w-full bg-white border border-gray-200'>
          <thead>
            <tr className='border-b border-gray-200'>
              <th className='p-2 text-left'>ID</th>
              <th className='p-2 text-left'>Name</th>
              <th className='p-2 text-left'>Category</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id} className='border-b border-gray-200'>
                <td className='p-2'>{product.id}</td>
                <td className='p-2'>{product.name}</td>
                <td className='p-2'>{product.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Used Images Section */}
      <div className='mb-6'>
        <h3 className='text-xl font-bold mb-4'>Used Images</h3>
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6'>
          {usedImages.map((image) => (
            <div key={image.id} className='relative'>
              <img
                src={image.src}
                alt={image.description}
                className='w-full h-auto object-cover rounded-lg shadow-md'
              />
              <p className='absolute bottom-2 left-2 bg-black text-white text-sm px-2 py-1 rounded'>
                {image.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Queries Section */}
      <div>
        <h3 className='text-xl font-bold mb-4'>Contact Queries</h3>
        <table className='min-w-full bg-white border border-gray-200'>
          <thead>
            <tr className='border-b border-gray-200'>
              <th className='p-2 text-left'>No</th>
              <th className='p-2 text-left'>Name</th>
              <th className='p-2 text-left'>Email</th>
              <th className='p-2 text-left'>Phone</th>
              <th className='p-2 text-left'>Address</th>
              <th className='p-2 text-left'>Message</th>
            </tr>
          </thead>
          <tbody>
            {contactQueries.map((query) => (
              <tr key={query.id} className='border-b border-gray-200'>
                <td className='p-2'>{query.id}</td>
                <td className='p-2'>{query.name}</td>
                <td className='p-2'>{query.email}</td>
                <td className='p-2'>{query.phone}</td>
                <td className='p-2'>{query.address}</td>
                <td className='p-2'>{query.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
