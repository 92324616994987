// src/components/FounderMessage.js

import React from "react";

const FounderMessage = () => {
  return (
    <div className='bg-blue-100 py-12 px-4 text-center'>
      <div className='max-w-3xl mx-auto'>
        <h2 className='text-3xl font-bold mb-4'>Message from Our Founder</h2>
        <p className='text-lg mb-4'>
        I am thrilled to share that I am embarking on a new and exciting journey – launching my own startup! 🚀 This is a huge step for me, and I couldn't be more excited about the path ahead.
        </p>
        <blockquote className='italic text-gray-700 mb-4'>
          "My experience at the University of Ministry has shaped me, and I am eager to apply that knowledge as I move forward. In the coming days, I will be launching new skill development courses, and I can’t wait to share more details with you soon!
 
 Your support and guidance mean the world to me, and I look forward to growing, learning, and creating something impactful with all of you by my side. 🌱✨"
        </blockquote>
        <p>
        Stay tuned and check out my new venture's website:
          <a
            href='https://eduteacheducation.com'
            className='text-blue-500 underline'
          >
            {" "}
            eduteacheducation.com
          </a>
          . <br /> Let’s make this journey incredible together! 💼📚
        </p>
        <p className='font-semibold mt-2'>— Palash Kaushik</p>
        <p className='text-sm'>Founder & CEO, Eduteach Education Services</p>
      </div>
    </div>
  );
};

export default FounderMessage;
