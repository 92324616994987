import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import { FaWhatsapp, FaPhoneAlt, FaEnvelope } from "react-icons/fa"; // Add the envelope icon for email

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='fixed top-0 left-0 w-full bg-gray-100 shadow-md z-50'>
      {/* Top Bar with Contact Info */}
      <div className='p-4 bg-blue-600 flex justify-between items-center text-sm'>
        {/* Contact Icons */}
        <div className='flex space-x-4 items-center text-white'>
          {/* WhatsApp */}
          <a
            href='https://wa.me/917905507571'
            target='_blank'
            rel='noreferrer'
            className='flex items-center'
          >
            <FaWhatsapp className='mr-2' />
            <span className='hidden md:inline'>+91 79055 07571</span>{" "}
            {/* Visible only on larger screens */}
          </a>
          {/* Phone */}
          <a href='tel:+917905507571' className='flex items-center'>
            <FaPhoneAlt className='mr-2' />
            <span className='hidden md:inline'>+91 79055 07571</span>{" "}
            {/* Visible only on larger screens */}
          </a>
          {/* Email */}
          <a
            href='mailto:artificialedu@eduteacheducation.com'
            className='flex items-center'
          >
            <FaEnvelope className='mr-2' />
            <span className='hidden md:inline'>
              artificialedu@eduteacheducation.com
            </span>{" "}
            {/* Visible only on larger screens */}
          </a>
        </div>

        {/* Register & Enquire Buttons */}
        <div className='flex space-x-4'>
          <Link
            to='https://docs.google.com/forms/d/e/1FAIpQLSfAlPuCwpWxJpZkGKYWk6teUjy5Xy5Nnbr0-vy_3JXDpmI9Tw/viewform?pli=1'
            target='_blank'
            className='bg-blue-500 text-white px-4 py-1 md:py-2 rounded-full hover:bg-blue-600 inline'
          >
            Register Now
          </Link>
          <Link
            to='/contact'
            className='bg-green-500 text-white px-4 py-1 md:py-2 rounded-full hover:bg-green-600 inline'
          >
            Enquire Now
          </Link>
        </div>
      </div>

      {/* Navbar with Logo and Menu */}
      <div className='container mx-auto flex justify-between items-center p-4'>
        {/* Logo */}
        <div>
          <Link to='/'>
            <img
              src={Logo}
              alt='Logo'
              className='h-12' // Adjust height as needed
            />
          </Link>
        </div>

        {/* Desktop Menu */}
        <div className='hidden md:flex space-x-6'>
          <Link to='/' className='font-semibold hover:text-gray-300'>
            Home
          </Link>
          <Link to='/about' className='font-semibold hover:text-gray-300'>
            About
          </Link>
          <Link to='/contact' className='font-semibold hover:text-gray-300'>
            Contact
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button
          className='md:hidden text-white focus:outline-none'
          onClick={toggleMenu}
        >
          <svg
            className='w-6 h-6 text-black'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16M4 18h16'
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-75 z-50 flex flex-col items-center transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className='flex flex-col items-center mt-8'>
          <Link to='/' className='text-white py-2 text-lg' onClick={toggleMenu}>
            Home
          </Link>
          <Link
            to='/about'
            className='text-white py-2 text-lg'
            onClick={toggleMenu}
          >
            About
          </Link>
          <Link
            to='/contact'
            className='text-white py-2 text-lg'
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
