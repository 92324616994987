import { useEffect, useState } from 'react';
import { RiWhatsappFill } from "react-icons/ri";

const WhatsAppIntegration = () => {
  const groupLink = "https://chat.whatsapp.com/DxxACkIfjbZ7sTgptR2UVn";

  const [position, setPosition] = useState('bottom-10');

  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll threshold as needed
      setPosition('bottom-20');
    } else {
      setPosition('bottom-10');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a
      href={groupLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`fixed right-4 bg-green-600 text-white rounded-full p-3 shadow-lg hover:bg-cyan-500 transition-all duration-500 ${position}`}
    >
      <RiWhatsappFill size={20} />
    </a>
  );
};

export default WhatsAppIntegration;
