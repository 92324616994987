import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaLinkedin,
} from "react-icons/fa";
import Logo from "../assets/logo.png";

const Footer = () => {
  return (
    <footer className='bg-white text-black shadow-2xl rounded-2xl p-4'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-center'>
        {/* Left Column */}
        <div className='flex flex-col items-center'>
          <img
            src={Logo}
            alt='Logo'
            className='h-[100px] w-[350px] object-contain'
          />
          <div className='flex flex-col md:flex-row gap-4 md:gap-0 md:items-center w-full justify-center text-sm'>
            <div className='flex items-center'>
              <FaMapMarkerAlt className='mr-1 text-cyan-500' />
              <span className='font-semibold mr-4'>
                Sanjay Nagar, Ghaziabad, 201002
              </span>
            </div>
            <div className='flex items-center'>
              <FaPhone className='mr-1 text-cyan-500' />
              <span className='font-semibold mr-4'>+91 - 79055 07571</span>
            </div>
            <div className='flex items-center'>
              <FaEnvelope className='mr-1 text-cyan-500' />
              <span className='font-semibold mr-4'>
                artificialedu@eduteacheducation.com
              </span>
            </div>
          </div>
        </div>

        {/* Right Column (Social Media Icons) */}
        <div className='flex justify-center space-x-4 '>
          <div className='my-auto flex justify-center space-x-4'>
            <a
              href='https://facebook.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaFacebook className='text-cyan-500' size={25} />
            </a>
            <a
              href='https://instagram.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaInstagram className='text-cyan-500' size={25} />
            </a>
            <a
              href='https://www.linkedin.com/in/eduteach-education/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaLinkedin className='text-cyan-500' size={25} />
            </a>
          </div>
        </div>
      </div>
      <h1 className='text-center text-sm font-semibold mt-1 p-1 text-cyan-400'>
        &copy; 2024, All Right Reserved
      </h1>
    </footer>
  );
};

export default Footer;
