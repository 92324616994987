import React from "react";
import ContactUss from "../utils/ContactUss";

const ContactUs = () => (
  <div className=' bg-gray-200 rounded-xl mt-[100px] md:mt-[200px]'>
    {/* Banner Section */}
    <div className='mb-5'>
      <img
        src='https://static.vecteezy.com/system/resources/previews/008/559/114/non_2x/contact-us-button-contact-us-text-web-template-sign-icon-banner-vector.jpg'
        alt='Contact Us Banner'
        className='w-full h-[200px] md:h-[300px] object-cover'
      />
    </div>

    <div className='container mx-auto px-4 md:px-8 pb-6 rounded-xl'>
      <ContactUss />
    </div>
  </div>
);

export default ContactUs;
