import React from "react";
import { FaHistory, FaLightbulb, FaStar } from "react-icons/fa";
import FounderMessage from "../utils/FounderMessage";

const About = () => {
  const topImage =
    "https://static.vecteezy.com/system/resources/previews/016/765/134/non_2x/about-us-button-web-banner-templates-illustration-vector.jpg";
  const historyImage =
    "https://miro.medium.com/v2/resize:fit:823/1*ujO8hBPWV64XStbmn8o5QA.jpeg";
  const innovationImage =
    "https://images.inc.com/uploaded_files/image/1920x1080/getty_691886452_405128.jpg";
  const premiumQualityImage =
    "https://static.vecteezy.com/system/resources/thumbnails/006/552/826/small_2x/premium-quality-product-100-percen-original-logo-template-illustration-free-vector.jpg";


  return (
    <div className='mt-[100px] md:mt-[200px]'>
      {/* Top Image */}
      <div className='w-full '>
        <img
          src={topImage}
          alt='Crown Hardware Banner'
          className='w-full h-[200px] md:h-[300px] object-cover'
        />
      </div>

      {/* Sections */}
      <FounderMessage />
    </div>
  );
};

export default About;
