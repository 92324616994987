export const navLinks = [
  {
    name: "Hardware",
    items: [
      {
        name: "Door Hardware",
        nested: [
          "Door Knobs",
          "Brass Mortise Handles",
          "ZInc Mortise Handles",
          "Brass Aldrops",
          "Door Knockers",
        ],
      },
      {
        name: "Window Hardware",
        nested: [
          "Casement Stays",
          "Casement Fastners",
          "Sash Lifts",
          "Tower Bolts",
        ],
      },
      {
        name: "Cabinet Hardware",
        nested: [
          "Cabinet Knobs",
          "Drawer Pulls",
          "Drop Bails",
          "Ring Handles",
          "Rosette Handles",
        ],
      },
      {
        name: "Iron Hardware",
        nested: ["Iron Hardware"],
      },
    ],
  },
  {
    name: "Bathroom Fittings",
    items: [
      {
        name: "Bath Accessories",
        nested: ["Bath Accessories"],
      },
      {
        name: "Copper Items",
        nested: [
          "Copper Sinks",
          "Copper Tiles",
          "Copper Sinks (Basins)",
          "Copper Bath Tubes",
        ],
      },
    ],
  },
  {
    name: "Miscellaneous",
    items: [
      {
        name: "Fireplace Accessories",
        nested: ["Companion Sets", "Fronts and Grates", "Fireside Accessories"],
      },
      {
        name: "Accessories",
        nested: [
          "Coat and Hat Hooks",
          "Letters and Numerals",
          "SS Door Handles",
          "Curtain Fittings",
          "Sign Plates",
          "KeyHole Escutcheons",
        ],
      },
      {
        name: "Catalogue",
        nested: ["Download catalogue"],
      },
    ],
  },
  { name: "About Us", items: [] },
  { name: "Contact Us", items: [] },
];

export const industries = [
  {
    id: 1,
    image:
      "https://m.media-amazon.com/images/I/71zhn68LlML._AC_UF894,1000_QL80_.jpg",
    title: "Custom Gate Hardware",
    description:
      "Crown Hardware specializes in designing and manufacturing bespoke custom gate hardware. Our products include custom gate hinges, latches, and handles, tailored to meet unique design specifications. We employ traditional sand casting and lost wax processes to create durable, high-quality fittings. Each piece can be finished to simulate aged patina or match specific architectural styles. Our expertise ensures that every custom gate hardware solution meets the highest standards of quality and craftsmanship.",
  },
  {
    id: 2,
    image:
      "https://image.made-in-china.com/2f0j00SwBhWPDYZRgj/Door-Hardware-Stainless-Steel-Solid-Popular-Points-on-Solid-Lever-Door-Handle.webp",
    title: "Custom Door Hardware",
    description:
      "Our custom door hardware offerings include a wide range of door handles, locks, and knockers, designed to enhance the aesthetics and functionality of your doors. Whether replicating historical designs or creating new, unique pieces, our skilled craftsmen use traditional techniques to ensure each item is of the highest quality. We offer various finishes to match or simulate aged patina, making our door hardware perfect for both classic and contemporary settings.",
  },
  {
    id: 3,
    image:
      "https://t4.ftcdn.net/jpg/09/07/67/33/360_F_907673314_BuhIjdACF80wvxrFZLl3Cua6C8ZYvZgp.jpg",
    title: "Custom Cabinet Hardware",
    description:
      "We offer a comprehensive range of custom cabinet hardware, including pulls, knobs, and hinges. Our products are designed to complement various cabinetry styles, from traditional to modern. Using our extensive experience in crafting high-quality fittings, we create pieces that are both functional and visually appealing. Each item is meticulously crafted and finished to meet specific customer requirements and enhance the overall look of your cabinets.",
  },
  {
    id: 4,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2fSrgyBQrYSwiMjXPIfjCC03E8I9U178IuQ&s",
    title: "Floor Registers & Vents",
    description:
      "Our custom floor registers and vents are designed to blend seamlessly with your flooring while providing efficient air distribution. Crafted using high-quality materials, these products are available in a variety of styles and finishes. We ensure that each register or vent is both functional and aesthetically pleasing, matching the design and finish of your interior. Our custom solutions offer durability and performance, meeting the highest standards of quality.",
  },
  {
    id: 5,
    image:
      "https://www.kichindia.com/assets/images/category_image/11698064799.jpg",
    title: "Architectural Hardware",
    description:
      "Crown Hardware excels in providing high-quality architectural hardware, including a range of door and window fittings. Our products are crafted using traditional techniques combined with modern manufacturing methods to ensure exceptional quality and longevity. Each item is finished to match specific architectural styles, providing both functionality and aesthetic appeal. Our dedication to craftsmanship and attention to detail ensures that every piece meets the highest standards.",
  },
  {
    id: 6,
    image:
      "https://www.traditionalbuilding.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_faces:center%2Cq_auto:good%2Cw_768/MTcwMDQyODgzNzA4MjMzMDgx/er-butler-hi-rez-006.jpg",
    title: "Historical Reproductions",
    description:
      "We specialize in creating accurate reproductions of historical hardware, preserving the design and craftsmanship of bygone eras. Our products are crafted to replicate historical styles and finishes, ensuring authenticity in every detail. Whether for restoration projects or new constructions seeking a vintage look, our reproductions meet the highest standards of quality and historical accuracy.",
  },
  {
    id: 7,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTthdxJla0LNSstr6uE_WKK4pxD9NiSw9ldEw&s",
    title: "Custom Design & Manufacturing",
    description:
      "Our custom design and manufacturing services cater to unique hardware requirements. From initial design concepts to final production, we work closely with clients to bring their visions to life. Our team uses advanced techniques and traditional methods to ensure each custom piece meets exact specifications. This service is ideal for clients seeking one-of-a-kind solutions tailored to their specific needs and preferences.",
  },
  {
    id: 8,
    image:
      "https://5.imimg.com/data5/SELLER/Default/2023/3/FG/AE/IB/11253654/premium-antique-double-doorkit-500x500.jpeg",
    title: "Premium Finishes",
    description:
      "We offer a wide range of premium finishes to complement our hardware products. From classic patinas to modern coatings, our finishes enhance both the appearance and durability of our fittings. Each finish is meticulously applied to ensure consistency and quality, providing a perfect match for various design styles. Our premium finishes are designed to withstand the test of time, maintaining their beauty and functionality.",
  },
];

export const staticData = {
  Hardware: {
    "Door Hardware": {
      "Door Knobs": {
        description: "Stylish and durable door knobs for all types of doors.",
        images: [
          {
            url: "http://crownhardware.co.in/files/resized/426697/265;265;b0e4160d169ac5fc09adeebcc3b84ffbad987b09.jpg",
            title: "Modern Brass Door Knob",
            description:
              "A sleek, modern brass door knob with a smooth finish.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426698/265;265;37d8b6e54a6ea1eee44e866f6c34a6c33d5e4100.jpg",
            title: "Antique Style Door Knob",
            description: "An antique-style door knob with intricate details.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426699/265;265;7da88c8417d170d1c634f65854ec433fc08a168f.jpg",
            title: "Elegant Crystal Door Knob",
            description:
              "A crystal door knob with an elegant and classic design.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426700/265;265;6a7475ba0c6a5f8cf5b0c3402655e5978d12b42c.jpg",
            title: "Sleek Chrome Door Knob",
            description: "A sleek chrome door knob with a contemporary look.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426701/265;265;d5f81aa5762c35717a00cc364fd3b2eeb3d686b2.jpg",
            title: "Vintage Bronze Door Knob",
            description: "A vintage bronze door knob with an aged appearance.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426702/265;265;ae87b661761709bafaeac7ae56ed2c9f44b324fa.jpg",
            title: "Contemporary Stainless Steel Door Knob",
            description:
              "A contemporary stainless steel door knob with a brushed finish.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426703/265;265;2bf00b57786513a9c824d3cf557da9502f8ad7a3.jpg",
            title: "Classic Wooden Door Knob",
            description:
              "A classic wooden door knob with a natural grain finish.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426704/265;265;2a9847aa78e136c6b9cb54f1ea5a28aaf0ecdd17.jpg",
            title: "Modern Nickel Door Knob",
            description:
              "A modern nickel door knob with a sleek, shiny surface.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426705/265;265;a8a489714bfb8517d4a14c8667a8ef68d9fbbbcd.jpg",
            title: "Minimalist Black Door Knob",
            description: "A minimalist black door knob with a matte finish.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426706/265;265;c8c54506ce4579152c08801ed819a7bdbdfaf8ff.jpg",
            title: "Sculptural Door Knob",
            description: "A sculptural door knob with an artistic design.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426696/265;265;aa24f42d7bab3b8f4d0b488ec13926b3352030f5.jpg",
            title: "Antique Copper Door Knob",
            description: "An antique copper door knob with a rich, warm tone.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426709/265;265;ee3fd3a1481358c8e122786373b147aab44a9f68.jpg",
            title: "Designer Door Knob",
            description:
              "A designer door knob with a unique, eye-catching pattern.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426711/265;265;c1bc5acb501da99e9bc2ebdbd64d5d1a00f60e81.jpg",
            title: "Modern Round Door Knob",
            description:
              "A modern round door knob with a polished chrome finish.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426712/265;265;d0d0d6c6d4557ce2ab9dcc80f7fdf7446ad2f944.jpg",
            title: "Classic Crystal Door Knob",
            description: "A classic crystal door knob with a luxurious look.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426710/265;265;78663894c36e0e523825f3823f9e7b943db0a3a7.jpg",
            title: "Polished Brass Door Knob",
            description: "A polished brass door knob with an elegant sheen.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426708/265;265;8b57438a4c8e90590482fe54705ef3c9202fbbbc.jpg",
            title: "Geometric Door Knob",
            description: "A geometric door knob with a modern design.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/426707/265;265;204fe2fab010087f378548fa3908befdbee72f34.jpg",
            title: "Art Deco Door Knob",
            description: "An Art Deco door knob with intricate detailing.",
          },
        ],
      },
      "Brass Mortise Handles": {
        description:
          "Elegant brass mortise handles suitable for traditional and modern doors.",
        images: [
          {
            url: "http://crownhardware.co.in/files/resized/322835/262;262;146bc3657bf3645a09833fc6882d2b13d7ca46e7.jpg",
            title: "Brass Mortise Handle 1",
            description:
              "An elegant brass mortise handle with detailed engravings.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322836/262;262;cd3b3fec062cc3c49b59319be92235b5ec2b4e77.jpg",
            title: "Brass Mortise Handle 2",
            description:
              "A traditional brass mortise handle with a classic design.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322837/262;262;5a31d433f3fd24e8af71f109609fab541dab3a75.jpg",
            title: "Brass Mortise Handle 3",
            description: "A polished brass mortise handle with a refined look.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301249/265;265;e0422e9e769008e90f2984c077367128e2a4df11.jpg",
            title: "Brass Mortise Handle 4",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322839/262;262;a99f361e6886573319ba6df8a1c66db935976f04.jpg",
            title: "Brass Mortise Handle 5",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322840/262;262;b4657d2b3cec399c5d17253fd66a18f35ce21024.jpg",
            title: "Brass Mortise Handle 6",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322841/262;262;ba6b0ab9831a63cdfb5741f512fa1c18be318f9a.jpg",
            title: "Brass Mortise Handle 7",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322842/262;262;bb8e90c668e38eef5f8bda97e81b0fee8274834d.jpg",
            title: "Brass Mortise Handle 8",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322843/262;262;9eafd7f44323b535b4be3825e1d86b17b2116e86.jpg",
            title: "Brass Mortise Handle 9",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322844/262;262;d85ab46d6e11bbc5118616f44fdab0332b1703a8.jpg",
            title: "Brass Mortise Handle 10",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/322845/262;262;dff4c7dc11863bfa0b0359493d15e0594a6aa9bb.jpg",
            title: "Brass Mortise Handle 11",
            description:
              "A decorative brass mortise handle with intricate patterns.",
          },
        ],
      },
      "Zinc Mortise Handles": {
        description: "Durable zinc mortise handles with a sleek finish.",
        images: [
          {
            url: "path/to/zinc-mortise-handle1.jpg",
            title: "Zinc Mortise Handle 1",
            description: "A sleek zinc mortise handle with a modern design.",
          },
          {
            url: "path/to/zinc-mortise-handle2.jpg",
            title: "Zinc Mortise Handle 2",
            description:
              "A durable zinc mortise handle with a minimalist style.",
          },
          {
            url: "path/to/zinc-mortise-handle3.jpg",
            title: "Zinc Mortise Handle 3",
            description:
              "A stylish zinc mortise handle with a contemporary finish.",
          },
        ],
      },
      "Brass Aldrops": {
        description: "High-quality brass alddrops for securing doors.",
        images: [
          {
            url: "http://crownhardware.co.in/files/resized/301247/265;265;ec4bea0fc5c6908289f7ee4f80dab67d13999948.jpg",
            title: "Brass Aldrop 1",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301248/265;265;918c7c8001c95c8fb4966188f6be0fec6d6b8a65.jpg",
            title: "Brass Aldrop 2",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301249/265;265;e0422e9e769008e90f2984c077367128e2a4df11.jpg",
            title: "Brass Aldrop 3",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301249/265;265;e0422e9e769008e90f2984c077367128e2a4df11.jpg",
            title: "Brass Aldrop 4",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301250/265;265;d2e6693eef4a27ef535a41299dae01f8d67e00f0.jpg",
            title: "Brass Aldrop 5",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301251/265;265;c910090683ab2d976425b3ec18259f511c165a7e.jpg",
            title: "Brass Aldrop 6",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301252/265;265;fffd4fb9442e8574b7e526a4f0f3b2ebac441c00.jpg",
            title: "Brass Aldrop 7",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301253/265;265;715514411a5e55a0a3616ed67c2fc99adc9a3de2.jpg",
            title: "Brass Aldrop 8",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301254/265;265;cd9b16611d657d698035ee4defaef33c6b2a1a7c.jpg",
            title: "Brass Aldrop 9",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301255/265;265;b155ff63f62e71cc85941b89c2b706ca4fbfa36b.jpg",
            title: "Brass Aldrop 10",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301256/265;265;46465eb24138e90a713b323c2378e60e60ee3305.jpg",
            title: "Brass Aldrop 11",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301257/265;265;ac8aeb889ac62b85e52ca780221a5dd32448bf8f.jpg",
            title: "Brass Aldrop 12",
            description:
              "An elegant brass alddrop with detailed craftsmanship.",
          },
        ],
      },
      "Door Knockers": {
        description: "Decorative door knockers to add charm to your entrance.",
        images: [
          {
            url: "http://crownhardware.co.in/files/resized/301938/265;265;07f5730c22131653676760ecca3a8a14792b7fd1.jpg",
            title: "Decorative Door Knocker 1",
            description:
              "A classic door knocker with intricate design elements.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301939/265;265;2b7accad1d3ffe26f154906f29c8f6d581d7f541.jpg",
            title: "Decorative Door Knocker 2",
            description: "A stylish door knocker with a modern aesthetic.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301940/265;265;e45f446d060f1d1d2a3340191a6ad7d214fb52d6.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301941/265;265;8b34ca9c47706b96f95fafd755164c586e701e34.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301942/265;265;6ddaf5ae416480e3ab1a3e124326afa7792c166d.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301943/265;265;ad55e4a3a37d949bf7940cdf90eaa655e8336cc6.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301944/265;265;36f10f8524d5067fa7541a4a4bd8a2eb889299df.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301945/265;265;c8831596ebc0b464522efe2366f86326989b1b27.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301946/265;265;d3897058e7310b6f455de4f21702511077568a30.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
          {
            url: "http://crownhardware.co.in/files/resized/301947/265;265;931a53ff0aaf51ca55d6446dac8a74b4a689e914.jpg",
            title: "Decorative Door Knocker 3",
            description: "An ornate door knocker with vintage charm.",
          },
        ],
      },
    },
    "Window Hardware": {
      "Casement Stays": {
        description: "Sturdy casement stays for securing window positions.",
        images: [
          {
            url: "path/to/casement-stay1.jpg",
            title: "Casement Stay 1",
            description: "A robust casement stay designed for durability.",
          },
          {
            url: "path/to/casement-stay2.jpg",
            title: "Casement Stay 2",
            description: "A sleek casement stay with a modern finish.",
          },
        ],
      },
      "Casement Fasteners": {
        description: "Reliable casement fasteners for secure window operation.",
        images: [
          {
            url: "path/to/casement-fastener1.jpg",
            title: "Casement Fastener 1",
            description:
              "A durable casement fastener with a secure locking mechanism.",
          },
          {
            url: "path/to/casement-fastener2.jpg",
            title: "Casement Fastener 2",
            description: "A stylish casement fastener with an elegant design.",
          },
        ],
      },
      "Sash Lifts": {
        description: "Easy-to-use sash lifts for operating window sashes.",
        images: [
          {
            url: "path/to/sash-lift1.jpg",
            title: "Sash Lift 1",
            description: "A practical sash lift designed for ease of use.",
          },
          {
            url: "path/to/sash-lift2.jpg",
            title: "Sash Lift 2",
            description: "A sleek sash lift with a contemporary look.",
          },
        ],
      },
      "Sash Fasteners": {
        description: "Reliable sash fasteners for secure window closures.",
        images: [
          {
            url: "path/to/sash-fastener1.jpg",
            title: "Sash Fastener 1",
            description: "A sturdy sash fastener with a robust locking system.",
          },
          {
            url: "path/to/sash-fastener2.jpg",
            title: "Sash Fastener 2",
            description: "An elegant sash fastener with a modern design.",
          },
        ],
      },
    },
    "Furniture Hardware": {
      "Cabinet Handles": {
        description: "Stylish cabinet handles for a wide range of furniture.",
        images: [
          {
            url: "path/to/cabinet-handle1.jpg",
            title: "Cabinet Handle 1",
            description: "A modern cabinet handle with a sleek finish.",
          },
          {
            url: "path/to/cabinet-handle2.jpg",
            title: "Cabinet Handle 2",
            description: "An elegant cabinet handle with intricate detailing.",
          },
          {
            url: "path/to/cabinet-handle3.jpg",
            title: "Cabinet Handle 3",
            description: "A classic cabinet handle with a timeless design.",
          },
        ],
      },
      "Drawer Pulls": {
        description: "Elegant drawer pulls for enhancing furniture aesthetics.",
        images: [
          {
            url: "path/to/drawer-pull1.jpg",
            title: "Drawer Pull 1",
            description: "A stylish drawer pull with a sleek, modern look.",
          },
          {
            url: "path/to/drawer-pull2.jpg",
            title: "Drawer Pull 2",
            description: "An elegant drawer pull with a classic design.",
          },
        ],
      },
      Hinges: {
        description: "Durable hinges for smooth door and cabinet operation.",
        images: [
          {
            url: "path/to/hinge1.jpg",
            title: "Hinge 1",
            description: "A heavy-duty hinge designed for durability.",
          },
          {
            url: "path/to/hinge2.jpg",
            title: "Hinge 2",
            description: "A sleek hinge with a modern finish.",
          },
        ],
      },
      "Drawer Slides": {
        description:
          "High-quality drawer slides for smooth and reliable movement.",
        images: [
          {
            url: "path/to/drawer-slide1.jpg",
            title: "Drawer Slide 1",
            description: "A reliable drawer slide with smooth operation.",
          },
          {
            url: "path/to/drawer-slide2.jpg",
            title: "Drawer Slide 2",
            description:
              "A high-quality drawer slide with durable construction.",
          },
        ],
      },
    },
  },
};
